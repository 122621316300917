<template>
  <b-card-code title="">
    <div class="row mb-2">
      <div class="col-lg-6">
        <h4>Viber feliratkozók</h4>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'avatar'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-b-modal.modal-edit @click="loadUserData(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="modal-edit" ref="modal-edit" title="Viber fiók monolit fiókhoz rendelése" hide-footer>
      <b-form-select :options="users" v-model="selected_person" text-field="name" value-field="id"/>
      <button class="btn btn-primary mt-1 float-right" @click="updateUserWithViberAccount()">Mentés</button>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      file: null,
      email: '',
      password: '',
      phone: '',
      name: '',
      users: [],
      selected: '',
      selectedID: '',
      selected_person: '',
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Avatar',
          field: 'avatar',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés E-mailre',
          },
        },
        {
          label: 'Név',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Viber ID',
          field: 'viber_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés viber id-ra',
          },
        },
        {
          label: 'Összekapcsolt felhasználó',
          field: 'user_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-success',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-info',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.loadUserList()
    this.usersList()
  },
  methods: {
    usersList() {
      axios({
        method: 'get',
        
        url: 'user/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.users = response.data
      })
    },
    uploadImage(id) {
      const formData = new FormData()
      formData.append('image', this.file)

      axios({
        method: 'post',
        
        url: `user/image/${id}`,
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'A kép sikeresen frissítve lett!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    onFileChange(e) {
      this.file = e.target.files || e.dataTransfer.files
    },
    loadUserData(id) {
      this.selectedID = id
    },
    updateUserWithViberAccount() {
      axios({
        method: 'post',
        
        url: `viber/set/${this.selectedID}`,
        data: {
          user_id: this.selected_person,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Felhasználói fiók szinkronizálva lett!',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.loadUserList()
        this.$bvModal.hide('modal-edit')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    deleteThisUser(id) {
      axios({
        method: 'delete',
        
        url: `user/delete/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.loadUserList()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Felhasználói fiók sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      })
    },
    loadUserList() {
      axios({
        method: 'get',
        
        url: 'viber/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
</style>
